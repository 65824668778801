import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { updatePostApplyDialog } from "../../../redux/actions/dialog_action";
import {
  bulkApplyJobs,
  clearBulkAppliedJobs,
  getPostApplyRecommendedJobs,
  triggerJobReload,
} from "../../../redux/actions/job_action";
import BulkApplyList from "./BulkApplyList";
import MobileAppAd from "./MobileAppAd";
import {
  AppliedCompanyText,
  AppliedJobContainer,
  AppliedJobListContainer,
  AppliedJobText,
  AppliedJobTextContainer,
  AppliedJobWrapper,
  BoxContainer,
  ButtonStyled,
  CheckIcon,
  CloseButton,
  CloseIcon,
  ContentText,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  HeaderStyled,
  LinkStyled,
  TextStyled,
  WarnIcon,
} from "./styles";

export default function PostApplyDialog(props) {
  const { page } = props;
  const isMobile = useMediaQuery("(max-width: 640px)");

  const router = useRouter();
  const dispatch = useDispatch();
  const posthog = usePostHog();

  let originUrl = "";

  if (typeof window !== "undefined") {
    originUrl = localStorage?.getItem("apply_origin");
  }

  const [selectedJobs, setSelectedJobs] = useState([]);

  const userResume = useSelector((state) => state?.user?.user?.state);
  const { showPostApplyDialog } = useSelector((state) => state.dialog);
  const {
    status: showDialog,
    scraped: isScraped,
    jobGlobalId,
  } = showPostApplyDialog || {};
  const applyingJobs = useSelector((state) => state?.jobs?.applyingJobs);
  const bulkAppliedJobs = useSelector((state) => state?.jobs?.bulkAppliedJobs);
  const jobListTab = useSelector((state) => state?.jobs?.jobListTab);
  const jobReload = useSelector((state) => state?.jobs?.jobReload);
  const recommendedJobs = useSelector((state) => state?.jobs?.recommendedJobs);

  const selectedJobsLength = selectedJobs?.length ?? 0;
  const bulkApplyJobsStatus = bulkAppliedJobs?.length > 0;

  const DialogHeaderIcon = useMemo(() => {
    if (!showDialog) return "";
    if ((isScraped && !bulkApplyJobsStatus) || userResume === "in_review")
      return <WarnIcon />;
    if (userResume === "approved") return <CheckIcon />;
    return null;
  }, [isScraped, userResume, showDialog, bulkApplyJobsStatus]);

  const DialogHeaderTitle = useMemo(() => {
    if (!showDialog) return "";
    if (isScraped && !bulkApplyJobsStatus) return "Proceed in New Tab";
    if (userResume === "approved") return "Application successful";
    return "Application In Progress";
  }, [isScraped, userResume, showDialog, bulkApplyJobsStatus]);

  const DialogHeaderSubtitle = useMemo(() => {
    if (isScraped) {
      return "Continue your application in the new tab, in the meantime apply to other similar jobs!";
    }

    const redirectLink = (
      <LinkStyled
        onClick={handleViewJobApplicationHistory}
        textDecoration="underline"
      >
        Job Application Status
      </LinkStyled>
    );

    const subtitleCopy =
      userResume === "approved"
        ? "Your application will be submitted in 30 minutes; review or undo it in your "
        : "Your application will be submitted after resume approval; review or undo in ";

    return (
      <>
        {subtitleCopy}
        {redirectLink}
      </>
    );
  }, [isScraped, userResume]);

  const ApplyButtonText = `Apply to ${
    selectedJobsLength === 6 ? "All" : "Selected"
  } (${selectedJobsLength})`;

  const disableApplyButton = selectedJobsLength === 0 || applyingJobs;

  function handleClose() {
    if (bulkApplyJobsStatus) {
      dispatch(triggerJobReload(!jobReload));
    }
    dispatch(clearBulkAppliedJobs());
    dispatch(
      updatePostApplyDialog({
        status: false,
        scraped: false,
        jobGlobalId: null,
      })
    );
    setSelectedJobs([]);

    if (router.pathname.includes("/apply")) {
      router.push(originUrl);
      localStorage.removeItem("apply_origin");
    }
  }

  function handleViewAllJobs() {
    if (page !== "job-list") {
      return handleClose();
    }

    router.push("/jobs");
  }

  function handleApplyBulkJob(jobs) {
    const jobIds = jobs.map((job) => job.id);

    const params = {
      jobIds: jobIds,
    };
    dispatch(bulkApplyJobs(params)).then((res) => {
      if (res.success) {
        posthog.capture(
          //posthog tracking (Apply Jobs button) [JSW-3026]
          "job_bulk_apply",
          {
            from_job: jobGlobalId,
            job_discovery: "post_apply_rec",
            num_jobs_apply: jobs.length,
            job_ids: jobIds,
          }
        );

        sendTrackingEvent(
          //tracking (Apply Jobs button) [JSW-3026]
          {
            event: "CE_job_bulk_apply",
            from_job: jobGlobalId,
            job_discovery: "post_apply_rec",
            num_jobs_apply: jobs.length,
            job_ids: jobIds,
          }
        );
      }
    });
  }

  function handleViewJobApplicationHistory() {
    window.open("/profile#job-application-history/?pending");
  }

  function handleReviewJobsApply() {
    if (!router.pathname.includes("/apply")) {
      handleClose();
      router.push("/profile#job-application-history/?pending");
    } else {
      router.push("/profile#job-application-history/?pending");
    }
  }

  useEffect(() => {
    if (jobGlobalId) {
      const params = {
        globalId: jobGlobalId,
        first: 6,
      };

      dispatch(getPostApplyRecommendedJobs(params));
    }
  }, [jobGlobalId]);

  return (
    <DialogContainer open={showDialog} onClose={handleClose}>
      <DialogHeader>
        <BoxContainer alignItems={"center"} justifyContent={"space-between"}>
          <BoxContainer gap={"0.5rem"} alignItems={"center"}>
            {DialogHeaderIcon}
            <TextStyled
              fontSize={isMobile ? "0.875rem" : "1.25rem"}
              fontWeight={"700"}
              lineHeight={"24px"}
            >
              {DialogHeaderTitle}
            </TextStyled>
          </BoxContainer>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </BoxContainer>
        <ContentText>{DialogHeaderSubtitle} </ContentText>
      </DialogHeader>

      {bulkApplyJobsStatus ? (
        <AppliedJobList bulkAppliedJobs={bulkAppliedJobs} />
      ) : (
        <BulkApplyList
          dialog={true}
          selectedJobs={selectedJobs}
          setSelectedJobs={setSelectedJobs}
          recommendedJobs={recommendedJobs}
        />
      )}

      <DialogFooter>
        {bulkApplyJobsStatus ? (
          <>
            <ButtonStyled onClick={handleReviewJobsApply}>
              Review Application
            </ButtonStyled>
            <ButtonStyled onClick={handleClose} variant={"filled"} black>
              Done
            </ButtonStyled>
          </>
        ) : (
          <>
            <ButtonStyled onClick={handleViewAllJobs}>
              View All Jobs
            </ButtonStyled>
            {recommendedJobs.length > 0 && (
              <ButtonStyled
                onClick={() => handleApplyBulkJob(selectedJobs)}
                variant={"filled"}
                disabled={disableApplyButton}
              >
                {ApplyButtonText}
              </ButtonStyled>
            )}
          </>
        )}
      </DialogFooter>
    </DialogContainer>
  );
}

function AppliedJobList(props) {
  const { bulkAppliedJobs } = props;

  return (
    <AppliedJobWrapper>
      <AppliedJobContainer>
        <HeaderStyled>Here are the job(s) you've applied to:</HeaderStyled>

        <AppliedJobListContainer>
          {bulkAppliedJobs.map((job, index) => {
            return (
              <AppliedJobTextContainer key={index}>
                <AppliedJobText>{job?.job?.title}, </AppliedJobText>
                <AppliedCompanyText>
                  {job?.job?.company?.name}
                </AppliedCompanyText>
              </AppliedJobTextContainer>
            );
          })}
        </AppliedJobListContainer>
      </AppliedJobContainer>
      <MobileAppAd />
    </AppliedJobWrapper>
  );
}
