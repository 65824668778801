import { useMediaQuery } from "@mui/material";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { decode } from "html-entities";
import posthog from "posthog-js";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as config from "../../../config/config";
import { formatSalary } from "../../../helpers/conditional_rendering";
import { getCompanyLogoImage } from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { PostApplyDialogSkeleton } from "../Skeleton/Skeleton";
import MobileAppAd from "./MobileAppAd";
import {
  BoxContainer,
  BulkJobWrapper,
  CardListContainer,
  CardWrapper,
  CheckBoxHover,
  CheckBoxIcon,
  CheckboxStyled,
  CheckboxWrapper,
  CheckedBox,
  ChipContainer,
  CompanyImage,
  CompanyNameStyled,
  DescriptionContainer,
  DetailChip,
  HeaderContainer,
  HeaderImage,
  HeaderStyled,
  JobSummaryContainer,
  JobSummaryText,
  JobSummaryTextBold,
  JobTitleStyled,
  MoreDetailButton,
  ScrollIndicator,
  ScrollIndicatorContainer,
  SelectAllButton,
  ShowMoreText,
  TitleContainer,
  UncheckedBox,
} from "./styles";

export default function BulkApplyList(props) {
  const { selectedJobs, setSelectedJobs, recommendedJobs, dialog } = props;

  const isMobile = useMediaQuery("(max-width: 640px)");

  const [hover, setHover] = useState(false);
  const [inView, setInView] = useState(null);

  const { fetchingRecommendedJobs } = useSelector((state) => state?.jobs);

  function checkedJob(job) {
    return selectedJobs.map((job) => job.id).includes(job?.id);
  }

  const checkedJobs = selectedJobs?.length > 0;

  const handleSelectText = useMemo(() => {
    if (checkedJobs) return "Deselect All";
    return "Select All";
  }, [checkedJobs]);

  function handleSelectAllJobs() {
    setSelectedJobs(recommendedJobs.map((job) => job));
  }

  function handleDeselectAllJobs() {
    setSelectedJobs([]);
  }

  function handleSelectFunction() {
    if (checkedJobs) {
      handleDeselectAllJobs();
    } else {
      handleSelectAllJobs();
    }
  }

  function handleSelectJob(job) {
    if (selectedJobs.map((job) => job.id).includes(job?.id)) {
      setSelectedJobs(
        selectedJobs.filter((selectedJob) => selectedJob.id !== job?.id)
      );
    } else {
      setSelectedJobs([...selectedJobs, job]);
    }
  }

  useEffect(() => {
    handleSelectAllJobs();
  }, [recommendedJobs]);

  return (
    <BulkJobWrapper>
      {recommendedJobs?.length > 0 || fetchingRecommendedJobs ? (
        <HeaderContainer $dialog={dialog}>
          <HeaderStyled>Similar jobs for you</HeaderStyled>
          <SelectAllButton onClick={handleSelectFunction}>
            {handleSelectText}
            <CheckboxWrapper
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <CheckboxStyled
                icon={<UncheckedBox size={"small"} />}
                checked={checkedJobs}
                checkedIcon={
                  <CheckedBox size={"small"}>
                    <CheckBoxIcon size={"small"} />
                  </CheckedBox>
                }
              />
              <CheckBoxHover>
                <CheckBoxIcon hover={hover} size={"small"} />
              </CheckBoxHover>
            </CheckboxWrapper>
          </SelectAllButton>
        </HeaderContainer>
      ) : null}
      <BoxContainer flexDirection={"column"}>
        <CardListContainer
          $noResult={recommendedJobs?.length === 0 && !fetchingRecommendedJobs}
          $dialog={dialog}
        >
          {fetchingRecommendedJobs ? (
            Array.from({ length: 6 }).map((_, index) => {
              return <PostApplyDialogSkeleton key={index} />;
            })
          ) : recommendedJobs?.length > 0 ? (
            recommendedJobs.map((job, index) => {
              return (
                <SimilarJobCard
                  key={index}
                  index={index}
                  job={job}
                  setInView={setInView}
                  checkedJob={checkedJob}
                  handleSelectJob={handleSelectJob}
                  dialog={dialog}
                />
              );
            })
          ) : (
            <MobileAppAd />
          )}
        </CardListContainer>

        {isMobile && (
          <ScrollIndicatorContainer>
            {Array.from({ length: recommendedJobs?.length }).map((_, index) => {
              return (
                <ScrollIndicator key={index} selected={inView === index} />
              );
            })}
          </ScrollIndicatorContainer>
        )}
      </BoxContainer>
    </BulkJobWrapper>
  );
}

function SimilarJobCard(props) {
  const { index, job, setInView, checkedJob, handleSelectJob, dialog } = props;

  const deviceTooSmall = useMediaQuery("(max-height: 720px)");

  const [hover, setHover] = useState(false);
  const [showFullSkills, setShowFullSkills] = useState(false);
  const [showFullBenefits, setShowFullBenefits] = useState(false);

  const [ref, entry] = useIntersectionObserver({
    threshold: 0.5, // Adjust this value as needed
  });

  const convertedGptSummary = useMemo(() => {
    if (!job?.gptSummary) return "";

    let regexHex = /&#x([0-9a-fA-F]+);/g;

    let result = job.gptSummary.replace(regexHex, (_, hexValue) => {
      let char = String.fromCharCode(parseInt(hexValue, 16));
      return char;
    });

    return decode(result);
  }, [job?.gptSummary]);

  const truncatedSkills = useMemo(() => {
    if (!job?.skills?.length) return "";

    let skillsString = job.skills.map((skill) => skill.name).join(", ");
    if (skillsString.length <= 70) return skillsString;

    let truncated = skillsString.slice(0, 70).trim();
    let lastCommaIndex = truncated.lastIndexOf(",");
    if (lastCommaIndex > 0) {
      truncated = truncated.slice(0, lastCommaIndex);
    }

    const remainingCount = job.skills.length - truncated.split(",").length;

    return showFullSkills ? (
      skillsString
    ) : (
      <>
        {truncated}...{" "}
        <ShowMoreText onClick={() => setShowFullSkills(true)}>
          +{remainingCount} more
        </ShowMoreText>
      </>
    );
  }, [job?.skills, showFullSkills]);

  const truncatedBenefits = useMemo(() => {
    if (!job?.company?.benefits?.length) return "";

    let benefitsString = job?.company?.benefits
      ?.map((benefit) => benefit?.title)
      .join(", ");
    if (benefitsString.length <= 70) return benefitsString;

    let truncated = benefitsString.slice(0, 70).trim();
    let lastCommaIndex = truncated.lastIndexOf(",");
    if (lastCommaIndex > 0) {
      truncated = truncated.slice(0, lastCommaIndex);
    }

    const remainingCount =
      job?.company?.benefits.length - truncated.split(",").length;

    return showFullBenefits ? (
      benefitsString
    ) : (
      <>
        {truncated}...{" "}
        <ShowMoreText onClick={() => setShowFullBenefits(true)}>
          +{remainingCount} more
        </ShowMoreText>
      </>
    );
  }, [job?.company?.benefits, showFullBenefits]);

  const company =
    job?.crossedBorder &&
    job?.crossBorderData?.source_country !=
      process.env.NEXT_PUBLIC_JSW_GEOLOCATION.toUpperCase()
      ? job?.crossBorderData?.company
      : job?.company;

  const companyLogoUrl = useMemo(() => {
    if (!company?.logo) return null;

    if (typeof company?.logo !== "string") {
      if (company?.logo !== null) {
        return company?.logo?.url;
      }
    }

    return company?.logo;
  }, [company?.logo]);

  const companyLogo = useMemo(() => {
    if (!companyLogoUrl)
      return (
        config?.assetDomain +
        "/images/backgrounds/hiredly-jobs-malaysia-wobb-background-v1.png"
      );

    return getCompanyLogoImage(companyLogoUrl);
  }, [companyLogoUrl, company?.logo]);

  //TRACKING RELATED
  function trackImpressionInView() {
    posthog.capture("job_impression", {
      origin: "job_application_popup",
      job_discovery: "similar_jobs",
    });

    sendTrackingEvent({
      event: "CE_job-impression",
      origin: "job_application_popup",
      job_discovery: "similar_jobs",
    });
  }

  const salaryRange = useMemo(() => {
    if (job?.salary === "Undisclosed" || job?.salary === "Undefined")
      return "Undisclosed";

    return formatSalary(job?.salary, job?.crossBorderData);
  }, [job?.salary, job?.crossBorderData]);

  useEffect(() => {
    if (entry?.isIntersecting) {
      setInView(index);
      trackImpressionInView(job, index);
    }
  }, [entry?.isIntersecting, index, setInView]);

  return (
    <CardWrapper
      $dialog={dialog}
      selected={checkedJob(job)}
      ref={ref}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => handleSelectJob(job)}
    >
      {!deviceTooSmall ? (
        <HeaderImage src={companyLogo} placeholder={!companyLogoUrl} />
      ) : null}

      <TitleContainer>
        <BoxContainer
          justifyContent={"space-between"}
          alignItems={"flex-start"}
        >
          <JobTitleStyled>{job?.title}</JobTitleStyled>
          <CheckboxWrapper>
            <CheckboxStyled
              icon={<UncheckedBox />}
              onClick={() => handleSelectJob(job)}
              checked={checkedJob(job)}
              checkedIcon={
                <CheckedBox>
                  <CheckBoxIcon />
                </CheckedBox>
              }
            />
            <CheckBoxHover>
              <CheckBoxIcon hover={hover} selected={checkedJob(job)} />
            </CheckBoxHover>
          </CheckboxWrapper>
        </BoxContainer>
        <BoxContainer alignItems={"center"} gap={"0.5rem"}>
          <CompanyImage src={companyLogo} />
          <CompanyNameStyled>{company?.name}</CompanyNameStyled>
        </BoxContainer>
      </TitleContainer>

      <DescriptionContainer>
        <ChipContainer>
          <DetailChip type={"jobType"}>{job?.jobType}</DetailChip>
          <DetailChip type={"state"}>{job?.stateRegion}</DetailChip>
          <DetailChip type={"salary"}>{salaryRange}</DetailChip>
        </ChipContainer>
        <JobSummaryContainer type={"summary"}>
          <JobSummaryTextBold>AI-Generated Summary:</JobSummaryTextBold>
          {convertedGptSummary !== "" && (
            <JobSummaryText>{convertedGptSummary}</JobSummaryText>
          )}
        </JobSummaryContainer>
        {job?.company?.benefits?.length > 0 && (
          <JobSummaryContainer type={"benefits"}>
            <b>Benefits: </b>
            {truncatedBenefits}
          </JobSummaryContainer>
        )}
        {job?.skills?.length > 0 && (
          <JobSummaryContainer type={"skills"}>
            <b>Skills: </b>
            {truncatedSkills}
          </JobSummaryContainer>
        )}
      </DescriptionContainer>
      <MoreDetailButton
        href={"/jobs/" + job?.slug + "?origin=similar_jobs"}
        target={"_blank"}
      >
        View Full Details
      </MoreDetailButton>
    </CardWrapper>
  );
}
