import CheckCircleIcon from "@heroicons/react/20/solid/CheckCircleIcon";
import ExclamationCircleIcon from "@heroicons/react/20/solid/ExclamationCircleIcon";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import Check from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "../../../assets/colors";

export const DialogContainer = styling(Dialog)({
  "& .MuiDialog-container .MuiPaper-root": {
    border: "1.5px solid " + Color.black,
    borderRadius: "1.5rem",
    overflow: "clip",

    maxHeight: "680px",
    maxWidth: "1168px",

    transition: "all 0.3s ease-in-out",

    "@media (max-width: 1279px)": {
      maxWidth: "720px",
    },

    "@media (max-width: 640px)": {
      maxHeight: "610px",
      maxWidth: "331px",
    },
  },
});

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const DialogHeader = styling("div")({
  display: "flex",
  flexDirection: "column",

  borderBottom: "1px solid" + Color.grey,

  padding: "1rem 1.5rem",

  "@media (max-width: 640px)": {
    padding: "0.75rem 0.75rem 0.5rem",
  },
});

export const DialogContent = styling("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",

  maxHeight: "552px",
  height: "100%",
});

export const DialogFooter = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",

  borderTop: "1px solid" + Color.grey,

  padding: "1rem",
});

export const BulkJobWrapper = styling("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  height: "100%",
  width: "100%",
});

export const HeaderContainer = styling("div")((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  padding: props?.$dialog ? "1rem 1.5rem 0" : "0",

  "@media (max-width: 640px)": {
    padding: "0.5rem 1rem 0",
  },
}));

export const CardListContainer = styling("div")((props) => ({
  display: "flex",
  gap: "1rem",
  overflowX: "auto",

  height: "100%",
  padding: props?.$dialog
    ? props.$noResult
      ? "1rem 1rem 0"
      : "0.75rem 1.5rem 1rem"
    : "1rem 0.125rem 0.75rem",

  margin: props?.$dialog ? "0 0 1rem" : "0",
  scrollBehavior: "smooth",

  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "transparent",
    height: 4,
    overflow: "auto",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 64,
    backgroundColor: Color.hiredlyPurple,
    overflow: "auto",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: 64,
    backgroundColor: Color.grey,
    margin: props?.$dialog ? "0 1.5rem" : "0 0.125rem",
    "@media (max-width: 640px)": {
      margin: props?.$dialog ? "0 1rem" : "0 0.5rem",
    },
  },

  "@media (max-width: 640px)": {
    gap: "0.5rem",
    padding: props.$noResult ? "0.5rem 0.5rem 0" : "0.375rem 1rem 0",
    margin: "0",
    scrollSnapType: "x mandatory",
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export const CardWrapper = styling("div")((props) => ({
  backgroundColor: "rgba(247, 247, 255, 1)",
  border: props.selected
    ? "1px solid" + Color.hiredlyPurple
    : "1px solid" + Color.secondaryGrey,
  borderRadius: "0.75rem",
  boxShadow: props.selected
    ? "0px 0px 0px 1.5px " + Color.hiredlyPurple
    : "none",

  display: "flex",
  flex: "0 0 auto",
  flexDirection: "column",
  alignItems: "center",

  height: props.$dialog ? "432px" : "370px",
  maxWidth: "248px",
  width: "100%",
  overflow: "clip",
  scrollSnapAlign: "center",

  transition: "all 0.15s ease-in-out",

  "&:hover": {
    border: "1px solid" + Color.hiredlyPurple,
    boxShadow: "none",
    cursor: "pointer",
  },

  "@media (max-width: 640px)": {
    maxWidth: "296px",
    maxHeight: "398px",
  },

  "@media (max-height: 700px)": {
    maxHeight: "302px",
  },
}));

export const TitleContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "0.25rem",

  maxHeight: "100px",
  height: "100%",
  width: "100%",
  padding: "0.625rem",
});

export const DescriptionContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",

  maxHeight: "192px",
  height: "100%",
  padding: "0.25rem 0.375rem 0.25rem 0.625rem",
  margin: "0 0.5rem 0 0",
  overflowY: "auto",
  scrollBehavior: "smooth",

  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "transparent",
    width: 4,
    overflow: "auto",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 64,
    backgroundColor: Color.hiredlyPurple,
    overflow: "auto",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: 64,
    backgroundColor: Color.grey,
  },
});

export const ChipContainer = styling("div")({
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: "0.4375rem",
});

export const AppliedJobWrapper = styling("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "1.5rem",

  width: "100%",
  padding: "1rem",

  "@media (max-width: 640px)": {
    gap: "1rem",
    padding: "0.5rem 1rem",
    overflowY: "auto",
    scrollBehavior: "smooth",
  },
});

export const AppliedJobContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.75rem",

  "@media (max-width: 640px)": {
    gap: "0.375rem",
  },
});

export const AppliedJobListContainer = styling("ul")({
  backgroundColor: Color.homepageGrey,
  borderRadius: "0.5rem",
  padding: "0.5rem 0.625rem 0.5rem 1.625rem",
  margin: "0",

  "@media (max-width: 640px)": {
    padding: "0.5rem 0.5rem 0.5rem 2.125rem",
  },
});

export const AppliedJobTextContainer = styling("li")({
  whiteSpace: "nowrap",

  "@media (max-width: 640px)": {
    whiteSpace: "wrap",
  },
});

export const ScrollIndicatorContainer = styling("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  padding: "0.5rem",
});

export const AdContainer = styling("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",

  borderRadius: "0.5rem",

  height: "199px",
  width: "100%",
  overflow: "clip",

  "@media (max-width: 640px)": {
    flexDirection: "column-reverse",
    border: "1px solid" + Color.grey,
    minHeight: "354px",
  },
});

export const AdTextContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "1.125rem",

  background: "linear-gradient(100deg, #512ACC 0.18%, #7556DC 41.6%)",

  height: "100%",
  width: "56%",
  padding: "2rem 1.5rem",

  "@media (max-width: 640px)": {
    gap: "0.5rem",
    height: "58%",
    width: "100%",
    padding: "0.875rem 1.5rem 1rem",
  },
});

export const AdArtContainer = styling("div")({
  backgroundColor: "#EFEFFD",
  width: "44%",

  "@media (max-width: 640px)": {
    height: "42%",
    width: "100%",
  },
});

export const QrContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  borderRadius: "0.75rem",
  border: "1px solid" + Color.black,
  backgroundColor: Color.white,

  height: "112px",
  width: "112px",
  padding: "0.75rem",

  "@media (max-width: 640px)": {
    borderRadius: "0.5rem",
    height: "96px",
    width: "96px",
    padding: "0.5rem",
  },
});

export const DownloadButtonsContainer = styling(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0.25rem",
}));

export const TextStyled = styling(Typography)({});

export const ContentText = styling(Typography)({
  color: "rgba(0, 0, 0, 0.60)",
  fontSize: "0.875rem",
  fontStyle: "italic",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
  },
});

export const HeaderStyled = styling(Typography)({
  fontSize: "1.25rem",
  fontWeight: 600,
  lineHeight: "24px",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
  },
});

export const JobTitleStyled = styling(Typography)({
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",

  fontSize: "0.875rem",
  fontWeight: 600,
  lineHeight: "24px",
  textOverflow: "ellipsis",

  overflow: "hidden",

  "@media (max-width: 640px)": {
    fontSize: "1rem",
  },
});

export const CompanyNameStyled = styling(Typography)({
  fontSize: "0.625rem",
  lineHeight: "20px",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",

  width: "9.5rem",
  overflow: "hidden",
});

export const JobSummaryContainer = styling("div")((props) => ({
  color: "rgba(0, 0, 0, 0.87)",
  backgroundColor: Color.white,
  border: "1px solid" + Color.lightGrey,
  borderRadius: "0.375rem",

  fontSize: "0.75rem",
  fontWeight: 400,
  lineHeight: "20px",

  padding: "0.5rem 0.625rem",
  height: "100%",

  ...(props?.type === "skills" && {
    background: "#F7FFFA",
  }),

  ...(props?.type === "benefits" && {
    background: "#FFFEF7",
  }),
}));

export const JobSummaryText = styling(Typography)({
  fontSize: "0.75rem",
  fontWeight: 400,
  lineHeight: "20px",
});

export const JobSummaryTextBold = styling(Typography)({
  color: "rgba(0, 0, 0, 0.6)",
  borderBottom: "1px solid" + Color.lightGrey,
  fontSize: "0.75rem",
  fontWeight: 700,
  lineHeight: "20px",
  paddingBottom: "0.375rem",
  marginBottom: "0.375rem",
});

export const ShowMoreText = styling("span")({
  fontWeight: "700",
  cursor: "pointer",
});

export const AppliedJobText = styling("span")({
  fontSize: "1rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
  },
});

export const AppliedCompanyText = styling("span")({
  color: "rgba(0, 0, 0, 0.60)",
  fontSize: "1rem",
  fontStyle: "italic",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
  },
});

export const AdTitle = styling(Typography)({
  color: Color.white,
  fontFamily: "Manrope",
  fontSize: "1.25rem",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textWrap: "wrap",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
    lineHeight: "21px",
  },
});

export const AdSubtitle = styling(Typography)({
  color: Color.white,
  fontFamily: "Manrope",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textWrap: "wrap",

  "@media (max-width: 640px)": {
    fontSize: "0.625rem",
    lineHeight: "15px",
  },
});

export const LinkStyled = styling("span")((props) => ({
  color: Color.hiredlyPurple,
  fontWeight: "700",
  textDecoration: props.textDecoration,

  "&:hover": {
    cursor: "pointer",
  },
}));

export const MoreDetailButton = styling("a")({
  color: Color.hiredlyPurple,
  fontSize: "0.875rem",
  fontWeight: 700,
  textDecoration: "none",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  maxHeight: "52px",
  height: "100%",

  "&:hover": {
    PointerEvents: "cursor",
  },

  "@media (max-width: 640px)": {
    padding: "0.625rem 1rem",
  },
});

export const CloseButton = styling(ButtonBase)({});

export const ButtonStyled = styling(ButtonBase)((props) => ({
  color: Color.black,
  backgroundColor: Color.white,

  border: "1px solid" + Color.black,
  borderRadius: "6.25rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: "500",
  letterSpacing: "0.074px",

  height: "42px",
  width: "200px",

  transition: "all 0.15s ease-in-out",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    letterSpacing: "0.055px",
    height: "30px",
    width: "100%",
  },

  ...(props.variant === "filled" && {
    color: Color.white,
    backgroundColor: props.black ? Color.black : Color.buttonGreen,
    border: "1.5px solid" + Color.black,

    fontWeight: "700",
  }),

  ...(props.disabled && {
    backgroundColor: Color.grey,
    border: "1px solid" + Color.grey,
    opacity: "0.5",
  }),
}));

export const SelectAllButton = styling(ButtonBase)((props) => ({
  color: Color.hiredlyPurple,

  display: "flex",
  alignItems: "center",
  gap: "0.5rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: 700,
  lineHeight: "24px",
  letterSpacing: "0.1px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
  },
}));

export const CheckboxWrapper = styling("div")((props) => ({
  position: "relative",
}));

export const CheckboxStyled = styling(Checkbox)({
  padding: "0",
  "& .Mui-checked": {
    color: Color.black,
  },

  "&:hover": {
    background: "transparent",
  },
});
export const CheckBoxHover = styling("div")((props) => ({
  position: "absolute",
  top: "0",
  left: "0",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  width: "100%",
  height: "100%",
}));

export const UncheckedBox = styling("div")((props) => ({
  backgroundColor: Color.white,
  border: "1px solid" + Color.black,
  borderRadius: props?.size === "small" ? "0.25rem" : "0.5rem",

  height: props?.size === "small" ? "18px" : "32px",
  width: props?.size === "small" ? "18px" : "32px",
}));

export const CheckedBox = styling("div")((props) => ({
  backgroundColor: Color.black,
  border: "1px solid" + Color.black,
  borderRadius: props?.size === "small" ? "0.25rem" : "0.5rem",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "&:hover": {
    backgroundColor: Color.white,
  },

  height: props?.size === "small" ? "18px" : "32px",
  width: props?.size === "small" ? "18px" : "32px",
}));

export const DetailChip = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "0.25rem",

  fontSize: "0.625rem",
  fontWeight: "700",
  whiteSpace: "nowrap",

  height: "1.3125rem",
  padding: "0 0.375rem",

  ...(props.type === "jobType" && {
    color: Color.blue,
    backgroundColor: "rgba(33, 61, 181, 0.14)",
  }),
  ...(props.type === "state" && {
    color: "rgba(129, 155, 72, 1)",
    backgroundColor: "rgba(129, 155, 72, 0.14)",
  }),
  ...(props.type === "salary" && {
    color: "rgba(155, 72, 72, 1)",
    backgroundColor: "rgba(155, 72, 72, 0.14)",
  }),
}));

export const ScrollIndicator = styling("div")((props) => ({
  border: "1px solid" + Color.darkPurple,
  borderRadius: "6.25rem",
  minWidth: "5px",
  minHeight: "5px",

  ...(props.selected && {
    backgroundColor: Color.darkPurple,
    minWidth: "10px",
    minHeight: "10px",
  }),
}));

export const QrImage = styling("img")({
  height: "100px",
  width: "100px",

  "@media (max-width: 640px)": {
    height: "80px",
    width: "80px",
  },
});

export const ArtImage = styling("img")({
  position: "absolute",
  top: -22,
  right: -10,
  objectFit: "contain",

  height: "140%",
  width: "auto",

  "@media (max-width: 640px)": {
    top: -28,
    right: -6,
    height: "60%",
  },
});

export const HeaderImage = styling("img")((props) => ({
  backgroundColor: Color.white,
  objectFit: props?.placeholder ? "cover" : "contain",

  height: "88px",
  width: "100%",
  padding: props?.placeholder ? "0" : "0.5rem",
}));

export const CompanyImage = styling("img")({
  backgroundColor: Color.white,
  border: "0.5px solid rgba(0, 0, 0, 0.38)",
  borderRadius: "0.25rem",

  height: "28px",
  width: "28px",
});

export const DownloadChip = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  color: Color.black,
  backgroundColor: Color.white,
  border: "0.5px solid" + Color.black,
  borderRadius: "0.375rem",

  width: "5.25rem",
  padding: "0.26rem 0.4575rem",
  userSelect: "none",
});

export const CloseIcon = styling(XMarkIcon)({
  color: Color.grey,
  height: "24px",
  width: "24px",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    color: Color.darkGrey,
  },
});

export const CheckIcon = styling(CheckCircleIcon)({
  color: Color.buttonGreen,
  height: "32px",
  width: "32px",

  "@media (max-width: 640px)": {
    height: "24px",
    width: "24px",
  },
});

export const WarnIcon = styling(ExclamationCircleIcon)({
  color: Color.warn,
  height: "32px",
  width: "32px",

  "@media (max-width: 640px)": {
    height: "24px",
    width: "24px",
  },
});

export const DownloadIcon = styling("img")({
  backgroundColor: Color.white,
  height: "18px",
  width: "auto",
});

export const CheckBoxIcon = styling(Check)((props) => ({
  color: props?.hover
    ? props?.selected
      ? Color.white
      : Color.black
    : Color.white,
  width: props?.size === "small" ? "12px" : "18px",
  height: props?.size === "small" ? "12px" : "18px",

  transition: "all 0.1s ease-in-out",
}));
